@import '../../scss/_globals.scss';

.tab__nav {
  list-style: none;
  margin-bottom: 1.25rem;
  padding: 0;
  font-size: 1.125rem;
  border-bottom: 1px solid $gray-4;

  li {
    display: inline-block;
    margin: 0 50px 0 0px;

    @mixin tab {
      color: $black;
      display: block;
      width: 100%;
      height: 100%;

      padding-bottom: 1.063rem;
      line-height: 1.625rem;
    }

    .disabled {
      @include tab();

      cursor: no-drop;
      color: rgba($black, 0.4);
    }

    a {
      @include tab();

      .badge {
        font-size: 0.875rem;
        border-radius: 0.625rem;
        display: inline-block;
        padding: 0.375rem 0.938rem;
        margin-left: 1.25rem;
        font-weight: bold;
      }

      &.active {
        border-bottom: 2px solid $primary;
        font-weight: bold;

        .badge {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}
