@import '../../scss/_globals.scss';

.news-text {
  max-width: 200px;
  width: max-content;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: normal;
}
