@import '../../scss/_globals.scss';


.table {
  width: 100%;
  margin: 0;
  color: $black;


  &__title {
    min-height: 2.625rem;
    line-height: 2.625rem;
    font-size: 1.5rem;
    font-weight: normal;
    color: $black;
    margin-bottom: 0.938rem;
  }

  &__loader {
    border-radius: 0.625rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($pale-gray, 0.7);
    backdrop-filter: blur(2px);
  }

  &__wrapper {
    position: relative;
    min-height: 240px;
  }

  &__content {
    border-collapse: separate;
    border-spacing: 0 0.313rem;
  }

  &__head {
    color: rgba($black, 0.35);
  }

  &__row {
    font-weight: bold;
  }
  td.error{
    background-color: #f149499e;
  }
  td.warning{
    background-color: #fff4e5;
  }

  &__cell,
  td {
    padding: 0.75rem 1.25rem;
    line-height: 1.5rem;
    font-size: 0.75rem;
    background-color: $white;
    border-bottom: 1px solid $gray-4;
    clear: both;

    &:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-left: 1px solid $gray-4;
    }

    &:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-right: 1px solid $gray-4;

      width: 1%;
      white-space: nowrap;
    }
  }

  &__fit-content {
    width: 1%;
    white-space: nowrap;
  }
}
