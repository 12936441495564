$pale-gray: #fbfcfe;
// $cool-gray: #afb3ba;
$cool-gray: #6f7175;
$dark-gray: #d1d1d1;
$dark-gray-2: #6f7175;
$dark-gray-3: #adb5bd;
$gray-3: #ebeff3;
$gray-4: #e7eaeb;
$gray-5: #eaecee;
$gray-6: #c4c4c4;
$gray-7: #e9ecef;

$white: #fff;
$white-two: #f1f1f1;
$black: #000;
$orange: #ef7b10;
$red: #ef2f2c;
$green: #9dd339;

$header-height: 113px;
$header-height-mobile: 85px;
$navbar-width: 280px;

$loader-size: 175px;
$loader-base-zIndex: 400;

$carousel-x-distance: 120px;

$layout-main-x-padding: 2rem;
$layout-main-y-padding: 3.125rem;

$footer-svg-height: 124px;

$custom-control-height: 1.563rem;

$z-index: 100;
$z-index-300: 300;

@import './_bootstrap.scss';
@import './_utils.scss';

/*
@font-face {
    font-family: 'EON';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/OpenSans-Regular.ttf');
}
*/
