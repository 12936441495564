/* Bootstrap variables */
$primary: $orange;
$danger: $red;
$warning: $orange;
$success: $green;
$info: $white-two;
$dark: $black;

$body-color: $black;
$font-size-base: 0.75rem;
$font-weight-base: normal;
$line-height-base: 1.16;
$body-bg: $pale-gray;

// $link-hover-decoration: none;

$grid-gutter-width: 10px;

$spacers: () !default;
$spacers: map-merge(
  (
    // 0px
      0: 0,
    // 5px
      1: 0.313rem,
    // 10px
      2: 0.625rem,
    // 15px
      3: 0.938rem,
    // 20px
      4: 1.25rem,
    // 30px
      5: 1.875rem,
    // 40px
      6: 2.5rem,
    // 50px
      7: 3.125rem
  ),
  $spacers
);

$border-radius: 0.625rem;

$input-color: $black;
$input-bg: $white;
$input-font-weight: normal;
$input-line-height: 0.875rem;
$input-font-size: 0.75rem;
$input-padding-y: 0.875rem;
$input-padding-x: 0.625rem;
// the select has a 24x24 icon, so 44px height: 44-24 - 2x 1px border
$input-select-padding-y: calc(0.625rem - 1px);

$input-height-inner: auto;
$input-height-inner-half: auto;
$input-height-inner-quarter: auto;

$input-height: auto;
$input-height-sm: auto;
$input-height-lg: auto;

$input-border-width: 1px;
$input-border-color: $gray-4;

$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-size: 0.75rem;
$input-btn-line-height: 0.75rem;
$btn-font-weight: bold;

$label-margin-bottom: 0.625rem;

$form-group-margin-bottom: 1.25rem;

$caret-width: 0.313rem;
$caret-spacing: 0.5rem;
$caret-vertical-align: 0.063rem;

$dropdown-font-size: 0.75rem;
$dropdown-padding-y: 0.25rem;
$dropdown-border-radius: 0.625rem;
$dropdown-box-shadow: 0 4px 4px 0 rgba($black, 0.25);
$dropdown-border-color: $gray-4;
$dropdown-bg: $white;

$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: 0.625rem;
$dropdown-link-color: rgba($black, 0.6);
