@import '../../scss/_globals.scss';

@mixin spinner($size) {
  border-radius: 50%;
  width: $size;
  height: $size;
}

@mixin full-sized {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.loader {
  &__blur {
    @include full-sized();

    backdrop-filter: blur(2px);
    background-color: rgba($pale-gray, 0.7);
    z-index: $loader-base-zIndex;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__container {
    @include full-sized();

    align-items: center;
    justify-content: center;

    &--absolute {
      z-index: $loader-base-zIndex + 10;
      position: absolute;
      left: 0;
      top: 0;
    }

    &--fixed {
      z-index: $loader-base-zIndex + 10;
      position: fixed;
      left: $navbar-width;
      top: $header-height;
      width: calc(100vw - #{$navbar-width});
      height: calc(100vh - #{$header-height});
    }

    @media (max-width: 992px) {
      left: calc(50% - 45px);
    }
  }

  &__wrapper {
    position: relative;
  }

  &__message {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: $loader-size;
    height: $loader-size;
    z-index: 20;
    font-family: Arial;
    font-weight: bold;
    line-height: 0.875rem;
    font-size: 0.75rem;
    color: $primary;
    user-select: none;
  }

  &__text {
    text-shadow: $pale-gray 0 0 20px;
  }

  &__dot {
    &--1 {
      animation: loader-dot-1 1s linear infinite;
    }
    &--2 {
      animation: loader-dot-2 1s linear infinite;
    }
    &--3 {
      animation: loader-dot-3 1s linear infinite;
    }

    @keyframes loader-dot-1 {
      0% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes loader-dot-2 {
      0% {
        opacity: 0;
      }
      21% {
        opacity: 0;
      }
      22% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes loader-dot-3 {
      0% {
        opacity: 0;
      }
      43% {
        opacity: 0;
      }
      44% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &__spinner {
    $speed: 1.1s;
    $border-size: 0.625rem;
    $invisible-border: $border-size solid $dark-gray;

    font-size: $border-size;
    position: relative;
    text-indent: -9999em;
    border-top: $invisible-border;
    border-right: $invisible-border;
    border-bottom: $invisible-border;
    border-left: $border-size solid $primary;
    transform: translateZ(0);
    animation: spinning-circle $speed infinite linear;

    @include spinner($loader-size);
    &::after {
      @include spinner($loader-size);
    }

    @keyframes spinning-circle {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
