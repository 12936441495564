@import '../../scss/_globals.scss';

$controlBottomMargin: 57px;

.carousel-wrapper {
  min-height: 1px; // IE11 fix

  .carousel {

    .carousel-inner {
      box-shadow: 0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.15);
      border: solid 0.625rem $white;
      background-color: $white-two;
    }

    a,
    a:hover {
      opacity: 1;
    }

  }

  .carousel-indicators {
    position: relative;
    margin: 1.25rem 0;

    li {
      margin: 0 0.375rem;
      opacity: 1;
      border-radius: 50%;
      width: 1.063rem;
      height: 1.063rem;
      box-shadow: inset 1px 2px 2px 0 rgba(0, 0, 0, 0.1);
      background-color: $white-two;

      &.active {
        background-color: $primary;
      }
    }
  }
}

.carousel {
  &__news {
    &__container {
      position: absolute;
      background-color: rgba($white, 0.85);
      padding: 1.75rem 1.75rem 0;
      height: 100%;

      &--left {
        width: 45%;
        left: 0;
        top: 0;
      }

      &--right {
        width: 45%;
        right: 0;
        top: 0;
      }

      &--center {
        text-align: center;        
        width: 45%;
        left: 0;
        right: 0;
        top: 0;
        margin-left: auto;
        margin-right: auto;
      }

      &--justify {
        width: 100%;
        left: 0;
        top: 0;
      }
    }

    &__message {
      font-size: 0.895rem;
      line-height: 1rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 25;
      -webkit-box-orient: vertical;

      @include ie11-only {
        display: block;
        // workaround for IE11
        max-height: calc(1rem * 25);
      }

      @media screen and (max-height: 600px) {
        font-size: 0.895rem;
        line-height: 1rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
      }
    }

    &__link {
      font-size: 1.125rem;
      margin-top: 1.25rem;

      a,
      a:hover {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
