@import '../../scss/_globals.scss';

.sample {
  &__box {
    display: flex;
    height: 100%;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 0.625rem $white;
    background-color: $white-two;
    max-width: 270px;
    padding: 0.625rem;
  }

  &__container {
    position: relative;
  }

  &__align_text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;

    @include ie11-only {
      display: block;
      overflow: hidden;
      max-height: 117px;
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: 0.625rem;
  }

  &__message {
    font-size: 0.625rem;
  }

  &__image {
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3.875rem;
    height: 3.875rem;
  }

  &__align_text {
    &--left {
      max-width: 40%;
    }

    &--right {
      margin-left: 60%;
      max-width: 40%;
    }

    &--center {
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    &--justify {
      width: 100%;
    }
  }
}
