@import '../../scss/_globals.scss';

.pager {
  &__arrow {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
  }

  &__state {
    line-height: 1.5rem;
    display: inline-block;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
