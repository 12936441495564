@import '../../scss/_globals.scss';

@include ie11-only {
  .input-group-append {
    margin-left: -2px;
  }
}

.dropdown {
  .dropdown-toggle {
    &:disabled {
      opacity: 0.4;
      cursor: no-drop;
    }
  }

  .dropdown-text-button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: $primary;
    font-size: 0.75rem;
    font-weight: bold;

    &:focus {
      outline: 1px auto rgba(0, 0, 0, 0.1);
    }

  
  }

  .user-menu {
    color: $black;
        }

  .dropdown-menu {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    min-width: max-content;

    left: -0.313rem;

    .dropdown-item {
      font-weight: bold;
      cursor: pointer;
    }
  }
 
}
