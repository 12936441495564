@import '../../scss/_globals.scss';

.add-field-btn {
  background-color: transparent;
  border: 0;
  line-height: 0.875rem;
  font-size: 0.75rem;
  color: $primary;
  font-weight: bold;
  margin-top: 0.313rem;
  margin-bottom: 1.25rem;
}
