
.offer-management{
    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }

    .card {
        .collapsed .down {
            display: block;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        .down {
            display: none;
        }

        .collapsed .up {display: none;}

        .up {
            display: block;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            margin-top: 5px;
        }
    }

    //OfferToolForm
    .offer-table{
        .dropArea{
            border-top: 100px solid #f1f1f1;
            min-height: 100px;
        }

        .draggingRow{
            border-left: 1px solid #000;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
        }

        input{
            padding: 0.275rem 0.125rem;
        }

        tr{
            th{
                padding: 0.875rem 0.325rem;
                border: 1px solid #e7eaeb;
            }
            td{
                padding: 0.275rem 0.125rem;
                border: 1px solid #e7eaeb;

            }
        }

    }
}
