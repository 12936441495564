@import '../../scss/_globals.scss';

#auth-body {
  background-color: $gray-3 !important;
  background-image: url(./images/login_bg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: $white;
    opacity: 0.6;
  }

  #root {
    @include ie11-only {
      justify-content: center;
      align-items: center;
    }
  }
}

.auth-block {
  margin: auto;

  @include ie11-only {
    margin: 0;
  }

  width: 678px;
  border-radius: 1.875rem;
  box-shadow: 0 44px 64px 0 rgba(0, 0, 0, 0.1);
  background-color: $pale-gray;
  padding: 3.125rem;
  position: relative;

  .logo {
    position: absolute;
    top: -1.25rem;
    right: 0;
    background-color: aqua;
  }

  .forgot-password {
    margin-bottom: 3.125rem;

    a {
      line-height: 1.125rem;
      font-weight: bold;
      color: $black;
      display: block;
    }
  }
}
