@import '../../scss/_globals.scss';



  .back-to-top {
    position: fixed;
    bottom: 0;
    right: 25px;
    border-radius: 4px;
    width: 50px;
    height: 50px; 
    background-color: $primary;
    color: $white;
    cursor: pointer;
    border: none;
    z-index: $z-index + 200;

  }

  .back-to-top:hover { 
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.2);
    transition: 0.15s;
    
  }
  
 