@import '../../scss/_globals.scss';

.layout {
  width: fit-content;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .navbar-toggler {
    border: none;

    &:focus {
      outline: none;
    }
  }

  &__solar-logo {
    cursor: pointer;
  }

  &__container {
    margin-top: $header-height;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;

    @include ie11-only {
      // needed for carousel height fix
      min-height: 1px;
    }
  }

  &__header {
    z-index: $z-index;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: $header-height;
    background-color: $pale-gray;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0 3.125rem;

   .navbar-light {
     :hover{
      text-decoration: none;
      color: $primary;
      transition: color 0.2s ease-in;
        }  
       }

    &__user {
      display: flex;
      align-items: center;
      font-weight: bold;

      &__name {
        margin-left: 0.75rem;

      }
    }

    .animated-icon {
      width: 30px;
      height: 20px;
      position: relative;
      margin: 0px;
      cursor: pointer;
    }

    .animated-icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
    }

    .animated-icon span {
      background: black;
    }

    .animated-icon span:nth-child(1) {
      top: 0px;
    }

    .animated-icon span:nth-child(2) {
      top: 10px;
    }

    .animated-icon span:nth-child(3) {
      top: 20px;
    }
  }

  &__nav {
    min-width: $navbar-width;
    width: $navbar-width;
    background-color: $white-two;
    border-right: 1px solid $gray-4;
    padding-bottom: $footer-svg-height;
    z-index: $z-index-300;
    height: 100%;

    .navbar-top-actions {
      padding: 20px 0;
    }

    .navbar-toggler {
      position: absolute;
      right: 10px;

      .animated-icon {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  &__main {
    flex: 1 1 auto;
    padding: $layout-main-y-padding $layout-main-x-padding
      calc(#{$footer-svg-height} + #{$layout-main-y-padding})
      $layout-main-x-padding;
    position: relative;
  }
}

@media (max-width: 992px) {
  .layout__header {
    height: $header-height-mobile;
  }

  .layout__container {
    margin-top: $header-height-mobile;
  }
}
