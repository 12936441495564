@import '../../scss/_globals.scss';

.croppie-container {
  .cr-slider-wrap {
    width: 100%;
    margin: 1.25rem auto;
  }

  .cr-slider {
    width: 100%;
  }
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 50%;
  background: #ddd;
  margin-top: -8px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 50%;
  background: #ddd;
  margin-top: -0.5rem;
}

.upload {
  &__container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 0.35);
    border: solid 1px $gray-5;
    background-color: $white;
    padding: 0.313rem;
  }

  &__description {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }

  &__description_h1 {
    font-size: 1.5rem;
  }
}
