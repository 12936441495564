@import '../../scss/_globals.scss';

.eon-footer{
  
  .footer-pic{
    margin-top: -75px;
  }
  
  .secound-row{
    background-color:#231f20;
    color: white;

    .center {
      text-align: center;
      font-size: 1.125rem;
    }

    .link {
      color: white;
    }
  
    .contact-text{
      font-size: 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      line-height: 0.2;
    }

    .copyright-column{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .copyright{
      margin-right: 2em;
    }

    .version{
      margin-right: 2em;
    }

    @media screen and (max-width: 769px){
      .contact-text{
        text-align: center;
      }

      .copyright{
        margin-top: 15px;
        text-align: center;
        float: none;
      }
    }
    
  }
}