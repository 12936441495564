.btn {
  box-shadow: 0 2px 3px 0 rgba($black, 0.15);

  &.btn-info {
    color: rgba($black, 0.6);
  }
}

button {
  &:focus {
    outline: 1px auto rgba($black, 0.1);
  }
}

.form-delimiter {
  width: 100%;
  border-top: 1px solid $gray-4;
  margin-top: calc(0.625rem - 1px);
  margin-bottom: 1.25rem;
}

form {
  input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  legend {
    line-height: 2.375rem;
    font-size: 1.5rem;
    border-bottom: 0;
    font-weight: normal;
    color: $black;
    margin-bottom: 1.25rem;

    &.primary {
      line-height: normal;
      font-size: 3.563rem;
      padding-bottom: calc(1.875rem - 1px);
      font-weight: bold;
      border-bottom: 1px solid $gray-4;
    }
  }

  label,
  .label {
    display: block;
    font-size: 0.75rem;
    min-height: 0.875rem;
    line-height: 0.875rem;
    color: rgba($black, 0.6);
    font-weight: bold;
    margin-bottom: 0.625rem;

    &.big {
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: $black;
    }
  }

  /**
  * Styling for checkboxes and radio fields
  */
  .form__check {
    // margin-bottom: 1.25rem;
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    color: $black;
    flex-direction: column;

    min-height: 2.75rem;
    margin: 0 1.25rem;

    &__group {
      .form-control-error {
        margin-top: -0.938rem;
        margin-bottom: 1.25rem;
      }
    }

    &__input {
      // hide the input box
      position: absolute;
      visibility: hidden;

      &:disabled ~ .form__check__label {
        cursor: auto;
      }

      &--checkbox:checked ~ .form__check__checkbox::before {
        background: $primary !important;
      }

      &--checkbox:disabled:checked ~ .form__check__checkbox::before {
        background: rgba($black, 0.35) !important;
      }

      &--radio:checked ~ .form__check__checkbox--radio::before {
        background: $green !important;
      }

      &--radio:disabled:checked ~ .form__check__checkbox--radio::before {
        background: rgba($black, 0.35) !important;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
    }

    &__column {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      min-height: 1.25rem;
      height: 100%;
    }

    &__checkbox {
      display: block;
      position: absolute;
      background-color: $white;
      border: 1px solid $gray-4;
      border-radius: 0.125rem;
      height: 1.125rem;
      width: 1.125rem;
      z-index: 5;

      // without this it would collapse on news form
      &.ie-fix {
        @include ie11-only {
          top: 12px;
        }
      }

      @include ie11-only {
        top: 1px;
      }

      &::before {
        display: block;
        margin: auto;
        content: '';
        border-radius: 0.125rem;
        height: 0.625rem;
        width: 0.625rem;
        position: relative;
        top: 0.188rem; // use fixed value, on IE11 calc doesn't work here
      }

      &--radio {
        border-radius: 100%;

        &::before {
          border-radius: 100%;
        }
      }
    }

    &__label {
      display: flex;
      align-items: center;
      padding-left: 1.75rem;
      min-height: inherit;
      font-weight: bold;
      z-index: 9;
      transition: all 0.25s linear;
      color: $black;
      margin: 0;
      cursor: pointer;

      @include ie11-only {
        height: 1px;
      }
    }
  }

  .form-group {
    .form-control,
    .form__select__control {
      &::placeholder,
      &.placeholder {
        font-size: 0.75rem;
        color: rgba($black, 0.35);
      }

      &:focus {
        box-shadow: 0 0 4px 0 $primary;
        border: solid 1px $primary;
      }

      &:disabled {
        background-color: $gray-4;
        cursor: no-drop;
        color: rgba($black, 0.5);
      }

      &[readonly] {
        color: rgba($black, 0.5);
      }
    }

    .inner-addon {
      position: relative;

      &.right-addon {
        .feedback {
          right: 0;
          padding-right: 0.625rem;
        }

        input {
          padding-right: 2.75rem;
        }
      }

      &.left-addon {
        .feedback {
          left: 0;
        }
      }

      .feedback {
        display: flex;
        height: 100%;
        align-items: center;
        z-index: 5;
        position: absolute;
        pointer-events: none;
      }
    }

    .form-control-feedback {
      color: rgba($black, 0.35);
    }
    .form__select-min-height {
        min-height: 55px !important;
    }

    .form__select {
      display: flex;
      max-height: 44px;

      &__control {
        display: flex;
        flex: 1;
        color: $input-color;
        border-radius: $input-border-radius;
        border: $input-border-width solid $input-border-color;
        background-color: $input-bg;
        font-size: $input-font-size;
        line-height: $input-line-height;
        padding: $input-select-padding-y $input-padding-x;
        padding-right: calc(#{$input-padding-x} + 3px);

        &--is-focused {
          box-shadow: 0 0 4px 0 $primary;
          border: solid 1px $primary;
          background-color: $white;
        }

        &--is-disabled {
          background-color: $gray-4;
          cursor: no-drop;
          color: rgba($black, 0.5);
        }
      }

      &--has-error {
        .form__select__control {
          border: 1px solid $danger !important;
        }
      }

      &__menu {
        margin: 0.375rem 0;
        border-radius: 0.625rem;
        border: solid 1px rgba($black, 0.35);
        background-color: $white;
        box-shadow: initial;
        z-index: 10; // switch has z-index 1, and they may overlap, so as a quick fix set z-index here

        &-list {
          border-radius: 0.625rem;
          padding: 0;
          margin: 0;
        }
      }

      &__option {
        padding: 0 1.5rem;
        margin: 0;
        min-height: 2.25rem;
        line-height: 2.25rem !important;
        color: rgba($black, 0.35);

        &--is-selected {
          background-color: $white;
        }

        &--is-focused {
          background-color: $white-two;
        }
      }

      &__single-value {
        color: $black;

        &--is-disabled {
          color: rgba($black, 0.5);
        }
      }

      &__value-container {
        padding: 0;
        margin: 0;
        position: initial;
      }

      &__placeholder {
        padding: 0;
        margin: 0;
        font-size: 0.75rem;
        color: rgba($black, 0.35);
      }
    }

    .file {
      position: relative;
      width: 100%;
      display: inline-block;
      cursor: pointer;
      min-height: 2.75rem;
      margin: 0;
      padding: 0;

      & input {
        width: 100%;
        margin: 0;
        opacity: 0;
        filter: opacity(0);
        /* font-size: 0 !important; */
      }

      .file-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;

        border-radius: 0.625rem;
        border: solid 1px $gray-4;
        background-color: $white;
        font-size: 0.75rem;
        line-height: 2.625rem;
        color: $primary;
        text-decoration: underline;
        font-weight: bold;
        padding-left: 0.625rem;

        .file-meta {
          float: right;
          padding-right: 0.5rem;
          color: rgba($black, 0.6);
          font-weight: normal;
        }

        &.disabled {
          background-color: #e7eaeb;
          cursor: no-drop;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .associatedValue {
        position: absolute;
        top: 15px;
        z-index: 5;
      }

      &__selected {
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;

        .file-custom {
          text-decoration: none;
          color: $black;
          padding-right: 0;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    .form-switch {
      display: flex;
      align-items: center;
      min-height: 2.75rem;
      padding: 0 $input-padding-x;

      @include ie11-only {
        height: 1px;
      }

      label {
        cursor: pointer;
      }

      .custom-control {
        padding-left: 0;
        width: 2.5rem;
        height: $custom-control-height;

        margin-left: 0.625rem;
        margin-right: 0.625rem;

        .custom-control-label {
          &::before {
            left: 0;
            top: 0;
            width: 2.5rem;
            height: $custom-control-height;
            border-radius: 3.125rem;
            border: solid 1px $gray-4;
          }

          &::after {
            left: 0.313rem;
            top: 0.313rem;
            width: 0.938rem;
            height: 0.938rem;
            background-color: $primary;
          }
        }
      }

      .custom-control-input:checked ~ .custom-control-label {
        &::after {
          background-color: $white;
        }
      }

      .custom-control-input:checked:disabled ~ .custom-control-label {
        cursor: auto;

        &::before {
          background-color: $gray-7;
        }

        &::after {
          background-color: $dark-gray-3;
        }
      }

      .custom-control-input:disabled ~ .custom-control-label {
        cursor: auto;

        &::before {
          background-color: transparent;
        }

        &::after {
          background-color: $dark-gray-3;
        }
      }
    }
  }

  .form-control-error {
    display: block;
    margin-top: 0.313rem;
  }

  .form-check-group {
    .form-control-error {
      margin-top: -0.938rem;
      margin-bottom: 1.25rem;
    }
  }

  .field-icon {
    display: flex;
    height: 100%;
    align-items: center;
    user-select: none;
    position: absolute;
    right: 0;
    margin-right: $input-padding-x;
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.875rem;

    .headline {
      display: block;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  ul {
    list-style: none;
    font-size: 0.875rem;
    margin: 0 0 1.25rem 0;
    padding: 0;

    li {
      padding-left: 0;
      &::before {
        content: '•';
        padding-right: 0.313rem;
      }
    }
  }
}

.datepicker {
  &__wrapper {
    width: 100%;
  }
}

.react-datepicker {
  &__triangle {
    left: 3.125rem !important;
  }

  &__time-list-item {
    &::before {
      content: '';
    }
  }
}

.react-datepicker-popper {
  z-index: 400 !important; /* Since Header and MessageBanner have higher z-index than 15 (originally set) Changed to 400 (always visible) */
}

.vertical-separator {
  display: flex;
  align-items: center;
  width: 1px;

  .vertical-separator-line {
    margin-top: 4px;
    width: 1px;
    background-color: $dark-gray;
    height: $custom-control-height;
  }
}

.selected-inverters-chip{
    border: 1px solid #ef7c12 !important;
    background-color: #fff !important;
    font-size: 0.75rem;
}


.input-negative-icon {
  position: relative;
  input {
    padding-left:12px;
  }
  &::before {
    position: absolute;
    top: 9px;
    margin-left: 5px;
    content:"-";
  }
}
