@import '../../scss/_globals.scss';

.messagebanner {
  position: sticky;
  top: $header-height;
  z-index: 12;
}

@media (max-width: 992px) {
  .messagebanner {
    top: $header-height-mobile;
  }

}