@import '../../scss/_globals.scss';

.preview {

  $width: calc(
    100vw - 17px - #{$navbar-width} - #{2 * $layout-main-x-padding} - #{2 *
      $carousel-x-distance}
  ) !important;

  &__dialog {
    width: fit-content;
    max-width: unset;
  }

  &__container {
    width: $width;
    max-width: 85%;
  }
}
