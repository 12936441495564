@import '../../scss/_globals.scss';

.modal {
  backdrop-filter: blur(5px);

  &-dialog {
    max-width: 1315px;
  }

  &-content {
    padding: 3.125rem 3.125rem 2.813rem;
    border-radius: 1.875rem;
    box-shadow: 0 44px 64px 0 rgba(0, 0, 0, 0.1);
    background-color: $pale-gray;
    border: 0;
  }
}

.modal-backdrop {
  &.show {
    opacity: 0.125;
  }
}
