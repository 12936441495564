@import '../../scss/_globals.scss';

.documents {
  &__button {
    color: $black;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    font-size: 1.125rem;
    padding: 0.625rem;
    border: 0;

    // &:focus {
    //   outline: none;
    // }

    &:hover {
      background-color: $white-two;
    }
  }

  &__row {
    @include ie11-only {
      height: 1px;
    }

    display: flex;
    align-items: center;
    min-height: 3.125rem;
    padding: 0 1.25rem;
    font-size: 0.75rem;
    border-radius: 0.625rem;
    background-color: $white;
    border: 1px solid $gray-4;
    margin-bottom: 0.313rem;

    &:hover {
      border: solid 1px $white-two;
      background-color: $white-two;
    }

    &--selected {
      border: solid 1px $dark-gray;
      background-color: $white-two;
    }
  }

  &__link {
    color: $black;
    font-weight: bold;

    &:hover {
      color: $black;
      text-decoration: underline;
    }
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: transparent;

  &-item {
    & + & {
      padding-left: 0.625rem;
    }

    & + &::before {
      padding-right: 0.625rem;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTguNTksMTYuNThMMTMuMTcsMTJMOC41OSw3LjQxTDEwLDZMMTYsMTJMMTAsMThMOC41OSwxNi41OFoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPjwvc3ZnPg==)
        left center no-repeat;
      background-size: 1.5rem;
      content: '';
      min-width: 2.125rem;
    }

    a {
      color: $black;
    }

    &.active {
      color: $black;
    }
  }
}
