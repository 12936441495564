@import '../../scss/_globals.scss';

.nav {
  $nav-item-height: 3.5rem;
  $nav-content-left-padding: 1.875rem;
  $nav-vertical-bar-width: 0.25rem;
  $nav-dropdown-item-height: 2.625rem;

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    color: $cool-gray;
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 1px;
    min-height: $nav-item-height;
    line-height: $nav-item-height;
    cursor: pointer;

    &__link {
      color: $cool-gray;
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding-left: $nav-content-left-padding;
      // outline: none;
      
      &:hover {
        text-decoration: none;
        color: $primary;
        transition: color 0.2s ease-in;
      }

      .active & {
        color: $white;
        background-color: $primary;
        padding-left: $nav-content-left-padding - $nav-vertical-bar-width;
      }
    }
    
     }

  &__icon {
    margin-right: 0.875rem;
  }

  &__dropdown_icon {
    margin-left: auto;
    margin-right: 1.875rem;
    height: $nav-item-height;
    line-height: $nav-item-height;
  }

  &__dropdown__list {
    list-style-type: none;
    padding: 0;
    background-color: $dark-gray;
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.3);
  }

  &__dropdown__item {
    min-height: $nav-dropdown-item-height;
    line-height: $nav-dropdown-item-height;

    a {
      padding-left: $nav-content-left-padding;
      color: $dark-gray-2;
      display: block;
    }

    &.active {
      a {
        color: $black;
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
    height: calc(100%);
  }
  .navbar-collapse.collapsing {
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
    left: -100%;
  }
  .navbar-collapse.show {
    left: 0;
    -webkit-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
  }
}

@media (min-width: 992px) {
  .navbar-top-actions {
    display: none;
  }

  .navbar-toggler {
    display: none;
  }
}
