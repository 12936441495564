
.order-container{
  label{
    font-weight: bold;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

  .card {
    background-color: #fbfcfe;

    .collapsed .down {
        display: block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .down {
        display: none;
    }

    .collapsed .up {display: none;}

    .up {
        display: block;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 5px;
    }
    .card-header{
      font-size: 1.125rem;
      background-color: #e9ecef
    }
    
    .card-body{
      .row{
        p{
          font-size: 0.775rem;
        }
      }
    }
}

  table{
    thead{
      tr{
        th{
          padding: 0.45rem;
          white-space: nowrap;
        }
      }
    }
    tbody{
      tr{
        td{
          padding-bottom: 0rem;
          padding-left: 0.2rem;
          padding-top: 0.1rem;
          padding-right: 0.1rem;
          line-height: 2rem;

          input{
            border-radius: 0;
            padding: 0.475rem 0.225rem;
            margin-bottom: 0;
            border:none;
          }
          .form-group{
            margin-bottom: 0;  
          }
          .form__check{
            min-height: 1rem;
            .form__check__column{
              min-height: 1rem;
              margin-top: 0.55rem;
            }
          }
        }
        
      }
    }
  }
}

.assignedUserNameField{
  background-color: transparent;
  border: none;
  margin-top:-5px;
  margin-left: -7px;
  .form-control:disabled, .form-control[readonly]{
    background-color: transparent;
    border: none;
    font-size: 0.855rem;
    color:#ef7b10 !important;
    font-weight: 700;
  }
  .form-control:focus{
    box-shadow: none;
  }
}

