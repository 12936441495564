@import './_globals.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './offerManagement.scss';

html {
  height: 100%;
}




body {
  //font-family: Arial;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Work Sans', sans-serif;
  overflow-y: scroll;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
}

h1 {
  line-height: unset;
  font-size: 3.563rem;
  @media screen and (max-width: 600px){
    font-size:1.5rem;
  }
  font-weight: bold;
  color: $black;
  border-bottom: 1px solid $gray-4;
  margin-bottom: 1.25rem;
  padding-bottom: calc(1.875rem - 1px);
}

h2 {
  line-height: 3rem;
  font-size: 2.625rem;
  font-weight: bold;
  color: $black;
  border-bottom: 1px solid $black;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

h3 {
  line-height: 2.625rem;
  font-size: 1.5rem;
  font-weight: normal;
  color: $black;
  margin-bottom: 1.25rem;

  &.br-bottom {
    min-height: 2.625rem;
    border-bottom: 1px solid $gray-4;
    line-height: normal;
  }
}

.trans-upper {
  text-transform: uppercase !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.nwse-resize {
  cursor: nwse-resize !important;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
  }

.cursor-initial {
  cursor: initial !important;
}

.text-underline {
  text-decoration: underline !important;
}

// these extra classes needed because of IE11...
.icon-24 {
  height: 24px;
  width: 24px;
}

.icon-34 {
  height: 34px;
  width: 34px;
}

.icon-44 {
  height: 44px;
  width: 44px;
}

.icon-90 {
  height: 90px;
  width: 90px;
}

.lh-inherit {
  line-height: inherit !important;
}

.lh-normal {
  line-height: normal !important;
}

.simplebar-scrollbar::before {
  background-color: $gray-6;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-track {
  border-radius: 7px;
  background-color: $white-two;
}

.simplebar-placeholder {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.us-none {
  user-select: none !important;
}

.w-fit {
  width: fit-content !important;
}

.modal-mw-small {
  min-width: 625px;
  width: fit-content;

  @include ie11-only {
    width: 1px;
  }
}

.left-0 {
  left: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.text-overflow-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@import './form.scss';

.customTable-ordermgm{

  table{
      thead{
          tr{
              td{
                  padding: 0.55rem;
              }
          }
      }
      tbody{
          tr{
              td{
                  padding: 0.55rem;
              }
          }
      }   
  }
}
